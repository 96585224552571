/* App.css */
.App {
  text-align: center;
  position: fixed;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  background-image: url('./img/black-leather.png');
  background-size: auto;
  background-repeat: repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Hide overflow to prevent scrollbars */
}

.black-bar-side {
  width: var(--black-bar-width);
  background: none;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
}

.drawer-open {
  position: absolute;
}

.black-bar-top {
  height: var(--black-bar-top-height);
  background-color: black;  
  background-image: url('./img/black-leather.png');
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  border: 1px solid #AFAFAF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.logo-small {
  display: block; /* Ensure the image is treated as a block element */
  height: 50px;
  width: auto;
  position: relative;
}

.black-bar-side.left {
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-left: 1px solid #AFAFAF;
  z-index: 1000;

}

.black-bar-side.right {
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 10px;
  border-right: 1px solid #AFAFAF;
  z-index: 1000;
}

.menu-button {
  cursor: pointer;
  z-index: 1002;
  font-size: 48px;
  margin-left: 20px;
  color: #AFAFAF;
  height: 50px;
  width: 50px;
  min-width: 50px;
  min-height: 50px;
  position: relative;
}

.menu-button:hover, .logo-small:hover, #shopping-cart-button-container:hover {
  transform: scale(1.05);
}

#shopping-cart-button-container {
  cursor: pointer;
  z-index: 1002;
  margin-right: 20px;
  margin-bottom: 7px;
  height: 50px;
  width: auto;
  transition: 0.1s ease-in-out;
  position: relative;
}

.cart-badge {
  position: absolute;
  top: 0;
  right: 38px;
  background-color: rgb(77, 12, 12);
  background-image: url('./img/red-leather.png');
  color: white;
  border: 1px solid #AFAFAF;
  border-radius: 10px;
  width: auto;
  padding: 0 5px;
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  font-family: 'Montserrat Alternates', sans-serif;
}


#shopping-cart-button {
  height: 100%; /* Ensure the img takes the full height of the container */
  width: auto;
}

/* CSS class for scaling up */
#shopping-cart-button-container.scaling-up {
  transform: scale(1.15);
}

/* CSS class for scaling down */
#shopping-cart-button-container.scaling-down {
  transform: scale(1);
}

.MuiSvgIcon-root {
  width: 90%;
  height: auto !important; /* Maintain the aspect ratio */
  max-height: 70px !important;
  min-height: 50px !important;
}

.MuiSvgIcon-root {
  width: 100% !important;
}

.drawer-list {
  padding: 0;
}

.MuiTypography-root {
  font-size: 1.5rem !important;
  font-family: 'Montserrat Alternates', sans-serif !important;
}

.drawer-content {
  width: 250px;
  max-width: 250px;
  background-color: black;
  background-image: url('./img/black-leather.png');
  color: white;
  height: 100%; /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  padding-top: var(--black-bar-top-height);
  border: 1px solid #AFAFAF;
  overflow-x: hidden;
}

.drawer-item {
  color: white;
  padding: 10px;
  background-color: black;
  background-image: url('./img/black-leather.png');
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  width: fit-content;
}

.drawer-item:hover {
  background-color: #1e150f!important;
  background-image: url('./img/brown-leather.png');
  transform: scale(1.05)
}

.drawer-item-text {
  font-size: 1.5rem !important;
  font-family: 'Montserrat Alternates', sans-serif !important;
}

.drawer-item::before {
  content: "";
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url('./img/bean.png'); /* Replace with your image source path */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px; /* Add some space between the image and text */
}

/* Update the cart item icon */
#cart-drawer-item::before {
  background-image: url('./img/shopping-cart.png'); /* Replace with your image source path */
}

.nav-drawer {
  position: fixed;
  animation-duration: 0.2s; /* Adjust the duration as needed */
  animation-timing-function: ease-in-out; /* Adjust the timing function as needed */
}

/* Apply the slideIn and slideOut animations */
.drawer-open {
  animation-name: slideIn;
}

.drawer-closed {
  animation-name: slideOut;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* For Chrome, Safari, and newer versions of Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Remove default margins */
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield; /* Restore the appearance to a regular text field */
  appearance: textfield; /* Restore the appearance to a regular text field */
}

:root {
  --black-bar-width: 5vw;
  --black-bar-top-height: 70px;
}

button {
  margin-top: 20px;
  margin-bottom: 80px;
  padding: 10px 20px;
  background-color: black; 
  background-image: url('./img/black-leather.png');
  color: white;
  border: none;
  cursor: pointer;
  font-size: 20px;
  border-radius: 10px;
}

@media (max-width: 500px) {
  :root {
    --black-bar-width: 0;
  }

  #shopping-cart-button-container {
    margin-right: 10px;
  }

  .menu-button {
    margin-left: 10px;
  }
}