body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2 {
  font-family: 'PlayFair Display SC', serif;
  text-transform: uppercase;
}
p, h3, button, input, label, select {
  font-family: 'Montserrat Alternates', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
