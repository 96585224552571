/* ProductInfoPage.css */
body, html {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.product-info-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;  
  background-image: url('./img/black-leather.png');
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  background-size: auto;
}

.product-info-content-and-bottom {
  position: absolute;
  height: 100vh;
}

.product-info-content {
  margin-top: var(--black-bar-top-height);
  margin-left: var(--black-bar-width);
  margin-right: var(--black-bar-width);
  display: flex;
  flex-direction: row;
  gap: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #c6bdb4;
  background-image: url('./img/background-no-logo.png');
}

.product-header {
  font-size: 3rem;
  text-align: center;
  padding: 0 15%;
  overflow-wrap: break-word;
}

.product-info-order-panel {
  border: 1px solid black;
  margin-left: 3vw;
  border-radius: 5px;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding: 20px;
  width: 90%;
  height: fit-content;
  background-color: white;
  background-image: url('./img/background0-no-logo.png');
  justify-content: center;
}

.product-main-image {
  width: 500px; /* Set a fixed width of 90% of the container width */
  max-width: 70vw;
  height: auto; /* Maintain the aspect ratio of the original image */
  border: 1px solid black;
  border-radius: 5px;
  transform: scale(1);
  transition: ease-in-out 0.2s;
}

.product-main-image:hover {
  transform: scale(1.03);
}

.product-info-panel {
  border: 1px solid black;
  margin-right: 3vw;
  border-radius: 5px;
  margin-top: 5rem;
  padding: 20px;
  width: 90%;
  background-color: white;
  background-image: url('./img/background0-no-logo.png');
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: transform 1s ease-in-out;
}

.info-field {
  text-align: left;
}

p {
  font-size: 1.2rem;
}

.info-field h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid black;;
}

.add-and-subscribe-button-container {
  display: column;
  gap: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;  
  margin-top:0;
}

.add-to-cart-button-contianer {
  width: fit-content;
  height: fit-content;
  padding: 0;
  margin-top: 0;
}

.subscribe-button-contianer {
  width: fit-content;
  height: fit-content;
  margin-top: 0;
  height: 2rem;

}

/* Hide the scrollbar for Firefox */
.product-info-page {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Hide the scrollbar for Chrome, Edge, and Safari */
.product-info-page::-webkit-scrollbar {
  width: 6px;
}

.product-info-page::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.image-dots {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem
}

.image-dot {
  padding: 2px;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ccc;
  transition: background-color 0.3s ease;
}

.image-arrow-left {
  padding: 0px;
  border: none;
  cursor: pointer;
  color: grey;
  width: 15px;
  height: 15px;
  font-size: 15px; /* Adjust the font size as needed */
  margin: 0 10px; /* Add some spacing between arrows and dots */
  background-image: url("./img/left_arrow.png");
  background-size: contain; /* Scale the image to fit while maintaining aspect ratio */
  background-repeat: no-repeat; /* Prevent image repetition */
  transition: ease-in-out 0.2s;
  transform: scale(1);
}

.image-arrow-right {
  padding: 0px;
  border: none;
  cursor: pointer;
  color: grey;
  width: 15px;
  height: 15px;
  font-size: 15px; /* Adjust the font size as needed */
  margin: 0 10px; /* Add some spacing between arrows and dots */
  background-image: url("./img/right_arrow.png");
  background-size: contain; /* Scale the image to fit while maintaining aspect ratio */
  background-repeat: no-repeat; /* Prevent image repetition */
  transition: ease-in-out 0.2s;
  transform: scale(1);
}

#left_arrow {
  background-image: url("./img/left_arrow.png");
  background-size: cover; /* Scale the image to fit while maintaining aspect ratio */
  background-repeat: no-repeat; /* Prevent image repetition */
  background-position: center; /* Center the image horizontally and vertically */
  width: auto;
  z-index: 1;
  border: 1px solid black; /* Remove the default button border */
  font-size: 16px; /* Set font size */
  cursor: pointer; /* Add a pointer cursor on hover */
}

#right_arrow {
  background-image: url("./img/right_arrow.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; 
  width: 20px; 
  height: 20px;
  border: none; 
  font-size: 16px;
  cursor: pointer; 
}

.image-dot.active {
  background-color: #828282;
}

.option {
  margin-top: 1rem;
  display: flex;
  max-width: 100vw;
}

.order-options {
  justify-content: center;
  text-align: center;
  align-items: center;
  width: fit-content;
  margin: auto;
  max-width: 100%;
}

.label-weight,
.label-grind-size,
.label-quantity {
  font-size: 1.5rem;
  margin-left: 1rem;
  margin-top: 15px;
  width: 8ch;
  text-align: left;
}

#weight {
  font-family: 'Montserrat Alternates', sans-serif;
  padding-bottom: 0;
  padding-top: 5px;
}

.label-subscribe {
  font-size: 1.5rem;
}

#decrease-quantity-control {
  margin-left: 0;
}

#increase-quantity-control {
  margin-right: 0;
}

.quantity-control {
  padding: 3px 10px;
  background-color: black;  
  background-image: url('./img/black-leather.png');
  color: white;
  border: none;
  cursor: pointer;
  font-size: 20px;
  border-radius: 2rem;
  margin-left: 0px;
  margin-right: 0px;
  width: 2rem;
  height: 2rem;
}

.quantity-input {
  margin-left: 20px;
  margin-top: 0px;
  height: fit-content;
  width: fit-content;
}

.label-total {
  margin-top: 0;
  text-align: left;
  font-size: 2rem;
  margin-right: 0.5rem;
}

.label-total-discount {
  text-align: left;
  margin-top: 0;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  color: rgb(126, 33, 33);

}

.total {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px;
}

.discounted-total {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px;
}

.total-price-text {
  width: 15ch;
  font-size: 2rem;
  margin-left: 1rem;
  text-align: right;
  font-family: 'Montserrat Alternates', sans-serif !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.total-discount-price-text {
  width: 15ch;
  font-size: 1.5rem;
  margin-left: 1rem;
  text-align: right;
  font-family: 'Montserrat Alternates', sans-serif !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: rgb(126, 33, 33);
}

.label-total.discounted {
  font-size: 1.5rem;
}

.total-price-text.discounted {
  color: grey;
  font-size: 1.5rem;
  text-decoration: line-through;
}

/* Hide the scrollbar for Firefox */
.total-price-text, .total-discount-price-text {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Hide the scrollbar for Chrome, Edge, and Safari */
.total-price-text::-webkit-scrollbar, .total-discount-price-text::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
}

.total-price-text::-webkit-scrollbar-thumb, .total-discount-price-text::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.option-input-field {
  margin-left: 20px;
  margin-right: 20px;
  padding: 3px 10px;
  background-color: black;  
  background-image: url('./img/black-leather.png');
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  border-radius: 10px;
  transform: scale(1);
  transition: ease-in-out 0.2s;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

#quantity {
  width: 80px; 
  padding-left: 15px;
  padding-right: 15px;
}

#add-to-cart-button {
  width: 20ch; /* Set a fixed width for labels, e.g., 25 characters */
  max-width: 100%;
  margin-bottom: 1rem;
  transition: ease-in-out 0.2s;
  transform: scale(1);
}

.add-to-cart-button-container {
  margin-bottom: 1rem;
}

#add-to-cart-button:not([disabled]):hover, .quantity-control:hover, .option-input-field:hover, .option-input-field:active {
  background-color: #1e150f;
  background-image: url('./img/brown-leather.png');
  transform: scale(1.03);  
  transition: ease-in-out 0.1s;
}

#add-to-cart-button:disabled {
  background-color: grey;
  background-image: url('./img/grey-leather.png');
  cursor: not-allowed;

}

#add-to-cart-button:not([disabled]):active {
  background-color: #1e150f;
  background-image: url('./img/brown-leather.png');
  transform: scale(1.07);
}

.image-arrow-left:hover, .image-arrow-right:hover {
  transform: scale(1.2);
}

#subscribe-checkbox {
  width: 2rem;
  height: 2rem;
  border-radius: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: 3px solid black;
  cursor: pointer;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  line-height: 2rem;
  background: none;
}

/* Custom checkmark */
#subscribe-checkbox:checked::before {
  content: '✔'; /* White checkmark */
  color: black;
  display: block;
  text-align: center;
}

.subscribe-checkbox-container {
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  height: fit-content;
  margin-top: 40px;
}

.subscription-dropdown-container {
  margin-top: 10px;
}

#subscription-dropdown {
  font-size: 1.5rem;
  margin: 0;
}

@media (max-width: 1200px) {
  .product-info-content {
    flex-direction: column; /* Change to column layout */
    align-items: center;
    padding: 1rem; /* Adjust padding as needed */
  }
  .product-info-order-panel {
    margin: 0;
    max-width: 80vw;
    margin-bottom: 0;
  }

  .add-to-cart-button-container {
    margin-bottom: 0;
  }
  
  .product-info-panel {
    margin: 0;
    max-width: 80vw;
  }
}

@media screen and (max-width: 768px) {
  .label-weight,
  .label-grind-size,
  .label-quantity {
    font-size: 1.1rem;
    margin: 0 0 10px 0;
    width: 8ch;
    text-align: left;
  }

  .total, .discounted-total {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 3rem;
  }
  
  .total-price-text, .total-price-text.discounted, .total-discount-price-text {
    font-size: 1.5rem;
    width: 10ch;
  }

  .label-total-discount, .label-total.discounted, .label-total {
    text-align: left;
    font-size: 1.5rem;
  }

  .label-subscribe {
    font-size: 1.1rem;
  }

  #quantity {
    margin: 0;
  }

  .quantity-control {
    margin: 0 10px;
  }

  .option-input-field {
    font-size: 1.2rem;
  }

  body {
    overflow-x: hidden;
  }
}

@media (max-width: 600px) {
  .product-header {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 510px) {
  .product-header {
    font-size: 2rem;
  }
  .info-field h2 {
    font-size: 1.5rem;
  }

  .total-price-text, .total-price-text.discounted, .total-discount-price-text {
    margin: 0;
    font-size: 1.2rem;
    margin-left: 10px;
  }

  .label-total-discount, .label-total.discounted, .label-total {
    margin: 0;
    margin-left: 10px;
    font-size: 1.2rem;
  }
  
  .total, .discounted-total {
    margin-left: 10px;
    margin-right: 10px;
  }

  #subscription-dropdown {
    font-size: 1.2rem;
  }

  .option {
    align-items: center;
    margin: auto;
    margin-top: 10px;
    flex-direction: column;
  }

  .quantity-input {
    margin: 0;
  }

  .label-weight,
  .label-grind-size,
  .label-quantity {
    width: 8ch;
    text-align: center;
  }
}

@media screen and (max-width: 430px) {
  .total-price-text, .total-price-text.discounted, .total-discount-price-text {
    width: 10ch;
  }
}

@media screen and (max-width: 350px) {
  .total, .discounted-total {
    flex-direction: column;
    align-items: center;
  }

  .total-price-text, .total-price-text.discounted, .total-discount-price-text {
    text-align: center;
    margin: 20px;
    width: 100%;
  }

  .label-total-discount, .label-total.discounted, .label-total {
    text-align: center;
    font-size: 1.5rem;
  }

  #subscription-dropdown {
    font-size: 1rem;
  }
}

@media screen and (max-width: 270px) {

  #quantity {
    max-width: 3ch;
  }

  .quantity-control {
    display: none;
  }

  #grind-size {
    max-width: 10ch;
  }

}

@media screen and (max-width: 215px) {
  .quantity-control {
    display: none;
  }

}