/* ContactUsPage.css */
body, html {
  margin: 0;
  padding: 0;
}

.contact-us-header {
  max-width: 100%;
  font-size: 4rem;
  border-bottom: 1px solid black;
  overflow-wrap: break-word; /* Allow words to break and wrap to the next line */
  word-wrap: break-word; /* Optional: For older browser compatibility */
}

.sub-section a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.sub-section a:hover{
  color: rgb(63, 63, 63);
  text-decoration: underline;
}

.contact-us-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  position: fixed;
  background-color: black;  
  background-image: url('./img/black-leather.png');
  background-size: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.contact-us-content-and-bottom {
  position: absolute;
  height: 100vh;
  width: 100%;
}

.contact-us-content {
  padding: 5%;
  background-color: #c6bdb4;
  background-image: url('./img/background-no-logo.png');
  margin-left: var(--black-bar-width);
  margin-right: var(--black-bar-width);
  margin-top: var(--black-bar-top-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  min-height: calc(100vh - var(--black-bar-top-height));
  overflow-x: hidden;

}

.contact-us-text-content {
  width: 90%;
  text-align: left;
  background-color: white;
  background-image: url('./img/background0-no-logo.png');
  border: 2px ridge black;
  border-radius: 5px;
  padding: 5%;
  overflow-y: auto;
}

.contact-us-page-page {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Hide Webkit scrollbars (Chrome, Safari, newer versions of Opera) */
.contact-us-page::-webkit-scrollbar {
  width: 0.1rem;
  background-color: transparent;
}

/* Hide Firefox scrollbar */
.contact-us-page {
  scrollbar-width: none;
}

/* Styles for sub-sections within contact-us-content */
.sub-section {
  margin-bottom: 40px;
  overflow-wrap: break-word; /* Allow words to break and wrap to the next line */
  word-wrap: break-word; /* Optional: For older browser compatibility */
}

.sub-section h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  overflow-wrap: break-word; /* Allow words to break and wrap to the next line */
  word-wrap: break-word; /* Optional: For older browser compatibility */
}

.sub-section p {
  font-size: 1.4rem;
  margin-bottom: 10px;
  line-height: 1.6;
  overflow-wrap: break-word; /* Allow words to break and wrap to the next line */
  word-wrap: break-word; /* Optional: For older browser compatibility */
}

@media (max-width: 768px) {
  .contact-us-header {
    font-size: 2.5rem;
  }

  .sub-section h2 {
    font-size: 1.5rem;
  }
  
  .sub-section p {
    font-size: 1rem;
  }
}

@media (max-width: 450px) {
  .contact-us-header {
    font-size: 2rem;
  }
}