.cart-page {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    background-size: contain;
    background-color: black;    
    background-image: url('./img/black-leather.png');
    background-size: auto;
    background-position: center;
    overflow-x: hidden;
    position: fixed;
}

.cart-page-content-and-bottom{
    position: absolute;
    height: 100vh;    
    width: 100%;
}

.cart-page-content {
    padding: 5%;
    background-color: #c6bdb4;
    background-image: url('./img/background-no-logo.png');
    margin-left: var(--black-bar-width);
    margin-right: var(--black-bar-width);
    margin-top: var(--black-bar-top-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: calc(100vh - var(--black-bar-top-height));
}

.cart-content {
    max-width: 80vw;
    width: 130ch;
    flex-direction: row;
    gap: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    color: black;
    border: 2px ridge black;
    border-radius: 5px;
    background-color: white;
    background-image: url('./img/background0-no-logo.png');
}

.order-items-container {
    width: 100%;
    border-bottom: 2px ridge black;
    border-top: 2px ridge black;
    overflow: auto;
    min-height: 1rem;
}

.cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;
    height: fit-content;
}

.cart-header h1 {
    margin: 20px;
    font-style: bold;
    font-size: 3rem;
}

.close-button {
    background: none;
    background-image: url("./img/close.png");
    background-size: cover;
    color: white;
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin: 10px;
    transform: scale(1);
    transition: ease-in-out 0.2s;
}

.close-button:hover {
    transform: scale(1.1);
}

.order-item {
    display: flex;
    border-bottom: 2px ridge black;
    width: 100%;
    overflow-x: hidden;
    justify-content: space-between;
}

.item-options {
    display: flex;
    width: fit-content;
}

.price-and-remove-container {
    display: flex;
    width: fit-content;
}

.order-item-image {
    margin-top: 1rem;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: 2px ridge black;
    border-radius: 5px;
}

.item-option {
    flex-wrap: wrap;
    padding: 10px;
    max-width: 100%;
}

.item-option label {
    border-bottom: 2px ridge black;
    font-weight: bold;
}

.item-option select {
    margin-top: 1rem;
}

input[type="number"] {
    margin-top: 1rem;
    width: 50px;
    padding: 5px;
}

.remove-button {
    background: none;
    background-image: url("./img/trash.png");
    background-size: cover;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 0;
    align-self:flex-end;
    transform: scale(1);
    transition: ease-in-out 0.2s;
    border-radius: 0;
}

.remove-button:hover {
    transform: scale(1.1);
}

.cart-total-before-tax, .cart-total-after-tax, .tax-total, .shipping-total {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    padding-bottom: 10px;
    font-size: 1.2rem;
    padding-left: 3%;
    padding-right: 3%;
}

.shipping-total {
    border-bottom: 1px solid black;
}

.cart-total-after-tax, .cart-total-price-after-tax-text {
    color: rgb(126, 33, 33);

}

.cart-total-price-before-tax-text, .tax-total-price-text, .shipping-total-price-text, .cart-total-price-after-tax-text {
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-align: right;
    margin-top: 0;
    margin-left: 20px;
    max-width: 30ch;
    margin-bottom: 0;
}

.cart-total-before-tax label, .cart-total-after-tax label, .tax-total label, .shipping-total label {
    text-align: left;
    width: 15ch;
    align-self: flex-start;
}

#weight-option-cart {
    width: 16ch;
}

#order-item-product-name-and-image-container {
    width: 12ch;
    transform: scale(1);
    transition: ease-in-out 0.2s;
    margin-left: 1ch;
    margin-right: 1ch;
}

#grind-size-option-cart {
    width: 22ch;
}

#quantity-option-cart {
    width: 10ch;
}

#subscription-option-cart {
    width: 20ch;
}

#price-label-and-text-cart {
    width: 15ch;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1ch;
    margin-right: 1ch;
}

#quantity-input-cart {
    padding-left: 10px;
}

.option-input-field-cart {
    padding: 3px 10px;
    background-color: black;    
    background-image: url('./img/black-leather.png');
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 10px;
    transform: scale(1);
    transition: ease-in-out 0.2s;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
}

.price-text {
    margin-top: 1rem;
    width: 100%;
    overflow-wrap: break-word; /* Allow words to break and wrap to the next line */
    word-wrap: break-word; /* Optional: For older browser compatibility */
    overflow-wrap: break-word; /* Allow words to break and wrap to the next line */
}

/* Hide the scrollbar for Firefox */
.cart-page {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
  
/* Hide the scrollbar for Chrome, Edge, and Safari */
.cart-page::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
}

.cart-page::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Style for the empty cart message */
.empty-cart-message {
    text-align: center;
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
    color: #555; /* Adjust the color to your preference */
}
  
/* Style for the Clear Cart button */
.clear-cart-button {
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    margin: 0 10px 5px;
    transform: scale(1);
    transition: ease-in-out 0.2s;
}

/* Style for the item count text */
.cart-item-count {
    font-weight: bold;
    font-size: 18px;
    margin: 0 0 0 10px;
}

.cart-item-count-and-clear {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    margin-bottom: 0;
    margin-top: 4rem;
}

/* Style for the cart summary section */
.cart-summary {
    display: column;
}

.required-field-text {
    text-align: left;
    margin: 20px;
    margin-top: 4rem;
    font-style: bold;
}

.order-form {
    align-items: center;
    text-align: center;
}
.form-field {
  display: flex;
  align-items: center;
  margin: 20px

}

.form-field label {
  width: 15ch;
  max-width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 1.2rem;
  justify-content: left;
  word-wrap: break-word;
  overflow-wrap: break-word; /* Allow words to break and wrap to the next line */
}

.form-field-input {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.form-field-input input {
    color: white;
    background-color: black;    
    background-image: url('./img/black-leather.png');
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex: 1;

}

.form-field input,
.form-field select,
.form-field textarea {
    width: 100%;
    padding: 5px;
    border: 2px ridge black;
    border-radius: 4px;
    text-align: left;
    max-width: 500px; 
}

.order-form h1 {
    padding-top: var(--black-bar-top-height);
    border-bottom: 2px ridge black;
    border-top: 2px ridge black;
}

#order-form-instructions {
    border-bottom: 2px ridge black;
    padding: 0 5%;
    text-align: left;
    margin-bottom: 3rem;
}

.clear-cart-button:disabled, .checkout-button:disabled, .submit-order-button:disabled {
    background-color:grey;
    background-image: url('./img/grey-leather.png');
    cursor: not-allowed;
}

.clear-cart-button:not([disabled]):hover, .checkout-button:not([disabled]):hover, .submit-order-button:not([disabled]):hover {
    background-color: #1e150f;
    background-image: url('./img/brown-leather.png');
    transform: scale(1.03);
    transition: ease-in-out 0.2s;
}

.option-input-field-cart:hover {
    background-color: #1e150f;
    background-image: url('./img/brown-leather.png');
    transform: scale(1.03);
}

#order-item-product-name-and-image-container:hover {
    transform: scale(1.03);
}

.order-item-product-name {
    text-decoration: none;
}

#order-item-product-link {
    text-decoration: none;
    color: black;
}

.go-to-products-button {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 1rem;
    transition: ease-in-out 0.2s;
    transform: scale(1);
}

.go-to-products-button:hover {
    background-color: #1e150f;
    background-image: url('./img/brown-leather.png');
    transform: scale(1.03);
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 1px solid black;

  }
  
  .modal-content {
    background-color: white;
    background-image: url('./img/background0-no-logo.png');
    color: black;
    width: 90%;
    max-width: 400px; /* Adjust the maximum width as needed */
    padding: 20px;
    border-radius: 5px;
    border: 1px solid black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center; /* Center-align all text within the modal */
  }
  
  .modal-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    transition: ease-in-out 0.2s;
    transform: scale(1);
    max-width: 100%;
    flex-direction: row;
  }

  .modal-button{
    margin: 10px;
    transition: ease-in-out 0.2s;
    transform: scale(1);
    font-size: 1rem
  }

  .modal-button:hover {
    background-color: #1e150f;
    background-image: url('./img/brown-leather.png');
    transform: scale(1.03);
  }
  
  .modal-buttons button:first-child {
    margin-right: 10px;
  }

  .contact-us-link {
    text-decoration: none;
    color: black;
    font-weight: bold;
  }

  ul {
    font-family: 'Montserrat Alternates', sans-serif;
  }

@media (max-width: 1200px) {
    #order-item-product-name-and-image-container {
        margin: 20px;
        width: 30ch;
        max-width: 60vw;
    }

    .order-item-image {
        width: 100%;
        height: auto;
    }

    .order-item {
        width: 100%;
        overflow: hidden;
        justify-content: space-between;
        align-items: center;
    }

    .item-options {
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 100%;
    }

    .price-and-remove-container {
        flex-direction: column-reverse;
        width: fit-content;
    }

    .order-items-container {
        width: 100%;
        overflow-x: hidden;
        height: 80%;
    }

    .cart-content {
        min-width: 96ch;
    }

    .cart-page-content-and-bottom {
        margin-left: 0;
        margin-right: 0;
    }

    .cart-item-count {
        font-size: 1rem;
    }

    .clear-cart-button {
        color: #fff;
        cursor: pointer;
        font-size: 0.8rem;
    }

    .empty-cart-message {
        width: 100%;
    }

    .cart-header {
        margin: 0;
    }

    .cart-header h1 {
        font-size: 2.5rem;
    }

    .item-option {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .item-option label {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    #weight-input-cart {
        margin-top: 2rem !important;
    }

    .item-option select, .item-option input, .item-option label {
        margin-top: 5px;
    }

    .item-option p {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .remove-button {
        margin-top: 5px;
        margin-bottom: 15px;
    }
    .form-field label {
        text-align: left;
        margin-left: 0;
    }
    .form-field {
        text-align: left;
    }

    .form-field-input{
        width: 100%;
    }
    .form-field-input input{
        width: 100%;
        margin: 0;
    }
}

@media (max-width: 900px) {
    .cart-content {
        min-width: 80vw;
    }
    .order-item {
        display: flex;
        flex-direction: column;
    }

    .price-and-remove-container {
        flex-direction: column;
    }

    .cart-header h1 {
        font-size: 2rem;
    }

    .form-field {
        flex-direction: column;
        align-items: flex-start; 
        margin: 30px;
    }
    .cart-total-price-before-tax-text, .tax-total-price-text, .cart-total-price-after-tax-text, .shipping-total-price-text {
        width: 24ch;
    }
}

@media (max-width: 600px) {
    .cart-total-price-before-tax-text, .tax-total-price-text, .cart-total-price-after-tax-text, .shipping-total-price-text {
        width: 15ch;
    }
}

@media (max-width: 500px) {
    .cart-total-price-before-tax-text, .tax-total-price-text, .cart-total-price-after-tax-text, .shipping-total-price-text {
        width: 10ch;
        align-items: flex-end;
    }
}



@media (max-width: 350px) {
    .cart-total-price-before-tax-text, .tax-total-price-text, .cart-total-price-after-tax-text, .shipping-total-price-text {
        width: 7ch;
        font-size: 1rem;
    }
    .cart-total-before-tax, .cart-total-after-tax, .tax-total, .shipping-total {
        font-size: 1rem;
    }
    .modal-buttons-container {
        flex-direction: column;
    }
}

@media (max-width: 250px) {
    .cart-total-before-tax, .cart-total-after-tax, .tax-total, .shipping-total {
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }
    .cart-total-price-before-tax-text, .tax-total-price-text, .cart-total-price-after-tax-text, .shipping-total-price-text {
        margin: 0;
        text-align: center;
        justify-content: center;
    }
    .cart-total-before-tax label, .cart-total-after-tax label, .tax-total label, .shipping-total label {
        text-align: center;
        justify-content: center;
    }

}

