/* FeaturedProductsPage.css */
body, html {
  margin: 0;
  padding: 0;
}

.featured-header {
  font-size: 4rem;
  border-bottom: 1px solid black;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word; /* Allow words to break and wrap to the next line */
}

.featured-products-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  position: fixed;
  background-color: black;  
  background-image: url('./img/black-leather.png');
  background-size: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.featured-products-content-and-bottom {
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.featured-products-content {
  padding: 0 10%;
  background-color: #c6bdb4;
  background-image: url('./img/background-no-logo.png');
  margin-left: var(--black-bar-width);
  margin-right: var(--black-bar-width);
  margin-top: var(--black-bar-top-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow-y: auto;
  justify-content: center;
  min-height: calc(100vh - var(--black-bar-top-height));
}

.product-card-featured {
  max-width: 350px;
  width: 100%;
  background-color: black;  
  background-image: url('./img/black-leather.png');
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px ridge #FFF;
  border-radius: 10px;
  height: auto;
  padding: 15px 5px;
  text-decoration: none;
  transform: scale(1);
  transition: ease-in-out 0.2s;
}

.product-card-featured:hover {
  background-color: #1e150f;
  background-image: url('./img/brown-leather.png');
  transform: scale(1.03);
}

.product-image-featured {
  width: 90%;
  height: 90%;
  object-fit: cover;
  border: 2px ridge #FFF;
  border-radius: 3px;
  margin-bottom: 20px;
}

.product-details-featured {
  align-items: center;
  height: auto;
  text-align: center;
  justify-content: center;
}

.product-details-featured:hover,
.product-details-featured h1:hover,
.product-details-featured h2:hover,
.product-details-featured h3:hover,
.product-details-featured p:hover
  {
  color: white;
}

.product-name-featured {
  margin-right: 10px;
  margin-left: 10px;
  padding: 0 10px;
  font-size: 2.4rem;
  border-bottom: 1px solid white;
  font-family: 'PlayFair Display SC', serif !important;
}

.product-farm-featured {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 1.5rem;
  padding: 0;
  font-family: 'PlayFair Display SC', serif;
  border-bottom: 1px solid white;
  padding: 0 10px;
  max-width: 500px;
}

.product-notes-featured {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1rem;
  text-align: center;
  border-bottom: 1px solid white;
  padding: 0 10px;
}

.product-roast-featured {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.4rem;
  text-align: center;
  text-align: center;
  border-bottom: 1px solid white;
}

/* Hide the scrollbar for Firefox */
.featured-products-page {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Hide the scrollbar for Chrome, Edge, and Safari */
.featured-products-page::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
}

.featured-products-page::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.featured-products-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr)); 
  height: 100%;
  padding: 20px;
  gap: 50px;
  justify-items: center;
  margin-bottom: 50px;
}

@media (max-width: 768px) {

  .product-image-featured {
    margin-top: 0;
  }

  .featured-header {
    font-size: 2.5rem;
  }
  .product-name-featured {
    font-size: 2rem;
    font-family: 'Montserrat Alternates', sans-serif;
  }

  .product-farm-featured {
    font-size: 1rem;
    padding: 0;
    font-family: 'PlayFair Display SC', serif;
  }

  .product-notes-featured {
    font-size: 0.8rem;
    font-family: 'Montserrat Alternates', sans-serif;
  }

}
