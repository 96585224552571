body, html {
  margin: 0;
  padding: 0;
}

.about-us-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  position: fixed;
  background-color: black;  
  background-image: url('./img/black-leather.png');
  overflow-x: hidden;
  overflow-y: auto;
  background-size: auto;
}

.about-us-content-and-bottom {
  position: absolute;
  height: 100vh;
  width: 100%;
}

.about-us-content {
  padding: 5%;
  background-color: #c6bdb4;
  background-image: url('./img/background-no-logo.png');
  margin-left: var(--black-bar-width);
  margin-right: var(--black-bar-width);
  margin-top: var(--black-bar-top-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  min-height: calc(100vh - var(--black-bar-top-height));
  overflow-x: hidden;

}

.about-us-text-content {
  width: 90%;
  text-align: left;
  background-color: white;
  background-image: url('./img/background0-no-logo.png');
  border: 2px ridge black;
  border-radius: 5px;
  padding: 5%;
  overflow-y: auto;
}

.about-us-header {
  font-size: 4rem;
  margin-bottom: 20px;
  text-align: left;
  border-bottom: 1px solid black;
}

.about-us-page h2{
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: 50px;
}

.about-us-page p {
  width: 90%;
  margin-bottom: 20px;
  font-size: 1.4rem;
  line-height: 1.6;
}

.about-us-page-page {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Hide Webkit scrollbars (Chrome, Safari, newer versions of Opera) */
.about-us-page::-webkit-scrollbar {
  width: 0.1rem;
  background-color: transparent;
}

/* Hide Firefox scrollbar */
.about-us-page {
  scrollbar-width: none;
}

.paragraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.paragraph p {
  margin-bottom: 20px;
  line-height: 1.6;
  text-align: justify;
}

.content-image {
  max-width: 50%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  object-fit: contain; /* Maintain aspect ratio and fit within the container */
}

@media (max-width: 768px) {
  .about-us-header {
    font-size: 2.3rem;
    margin-bottom: 20px;
  }
  .about-us-page h2{
    font-size: 2rem;
    margin-bottom: 20px;
    margin-top: 50px;
  }
  .about-us-page p {
    margin-bottom: 20px;
    font-size: 1rem;
    line-height: 1.6;
  }

  .content-image {
    max-width: 100%;
  }
}