
body, html {
  margin: 0;
  padding: 0;
}

.all-products-page {
  background-color: black;  
  background-image: url('./img/black-leather.png');
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  height: 100%;
}

.all-products-content-and-bottom {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.all-products-content {
  background-color: #c6bdb4;
  background-image: url('./img/background-no-logo.png');
  margin-top: var(--black-bar-top-height);
  margin-left: var(--black-bar-width);
  margin-right: var(--black-bar-width);
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: calc(100vh - var(--black-bar-top-height));
}

.all-products-header {
  border-bottom: 1px solid black;
  font-size: 4rem;
  max-width: 70vw;
  word-wrap: break-word;
  overflow-wrap: break-word; /* Allow words to break and wrap to the next line */
}

.search-header-container {
  text-align: left;
  padding: 0 15%;
  width: 70%;
  margin-bottom: 2rem;
}

.search-input {
  position: sticky;
  max-width: 80vw;
  font-size: 1.5rem;
  background-color: black;  
  background-image: url('./img/black-leather.png');
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 7px;
  border: 3px solid black;
  border-radius: 10px;
  transform: scale(1);
  transition: ease-in-out 0.2s;
}

.search-input:hover, .search-input:active {
  transform: scale(1.03);
  background-color: #1e150f;
  background-image: url('./img/brown-leather.png');
}

.products-container {
  margin: 0 15%;
  background-color: white;
  background-image: url('./img/background0-no-logo.png');
  border: 2px ridge black;
  border-radius: 5px;
  flex-grow: 1;
  margin-bottom: 100px;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
  height: 100%;
  padding: 20px;
  gap: 50px;
  justify-items: center;
}

.no-products-found {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.product-card {
  text-decoration: none;
  color: inherit;
  background-color: black;  
  background-image: url('./img/black-leather.png');
  color: white;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 90%;
  border-radius: 10px;
  overflow: hidden;
  transform: scale(1);
  transition: ease-in-out 0.2s;
}

.product-card:hover {
  background-color: #1e150f;
  background-image: url('./img/brown-leather.png');
  transform: scale(1.03) translateY(5px);
}

.product-image-gridview {
  width: 90%;
  height: auto;
  object-fit: cover;
  border: 2px ridge #FFF;
  border-radius: 3px;
  margin-bottom: 20px;
}

.product-details {
  margin-top: 0;
  width: 100%;
  display: column;
  align-items: center; /* Vertically center the text */
  justify-content: center; /* Horizontally center the text */
  word-wrap: break-word;
  overflow-wrap: break-word; /* Allow words to break and wrap to the next line */
}

.product-name {
  padding: 0 10%;
  height: fit-content;
  line-height: 1.1em; /* Half of max-height to create two lines */
  margin-top: 0;
  font-size: 1.2rem;
  max-width: 100%; /* Ensure the text doesn't overflow horizontally */
  white-space: normal; /* Prevent the text from wrapping to the next line */
  text-overflow: hidden; /* Add ellipsis (...) if the text overflows vertically */
  overflow: hidden; /* Hide overflowing text */
  border-bottom: 2px ridge #FFF;
  font-family: 'PlayFair Display SC', serif !important;
}

.product-roast {
  font-size: 0.9rem;
  border-bottom: 2px ridge #FFF;
}

/* Hide the scrollbar for Firefox */
.products-container {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Hide the scrollbar for Chrome, Edge, and Safari */
.products-container::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
}

.products-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

@media (max-width: 768px) {
  .all-products-header {
    font-size: 3rem;
  }
  .search-input {
    width: 90%;
    font-size: 1rem;
  }
}

@media (max-width: 400px) {
  .all-products-header {
    font-size: 2rem;
  }

  .products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); 
    height: 100%;
    padding: 5px;

  }
}