body, html {
  margin: 0;
  padding: 0;
}

.landing-page {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  background-position: center;
  position: fixed;
  overflow-x: hidden;
  background-color: black;
  background-image: url('./img/black-leather.png');
  background-size: auto;
}

.landing-content-and-bottom {
  position: absolute;
  width: 100vw;
}

.landing-content {
  padding: 0 10%;
  text-align: center;
  background-color: #c6bdb4;
  background-image: url('./img/background-no-logo.png');
  margin-top: var(--black-bar-top-height);
  margin-left: var(--black-bar-width);
  margin-right: var(--black-bar-width);
  position: relative;
  overflow-y: auto;
  min-height: calc(100vh - var(--black-bar-top-height));
}

.logo,
.mantra,
.mission,
.enter-button {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s;
}

.logo.show,
.mantra.show,
.mission.show,
.enter-button.show {
  opacity: 1;
  transform: translateY(0);
}

.logo {
  margin-top: 50px;
  width: 500px;
  max-width: 60%;
}

.mantra {
  font-style: italic;
  font-size: 2rem;
  margin-top: 0px;
  margin-bottom: 30px;
}

.missions {
  padding: 5% 0;
  border: 2px ridge black;
  border-radius: 5px;
  background-color: white;
  background-image: url('./img/background0-no-logo.png');
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s;
}

.mission0,
.mission1,
.mission2,
.mission3,
.mission4 {
  font-size: 1.4rem;
  margin-top: 10px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s;
}

.mission0.show,
.mission1.show,
.mission2.show,
.mission3.show,
.mission4.show,
.missions.show {
  opacity: 1;
  transform: translateY(0);
}

.hidden {
  display: none;
}

.enter-button {
  margin-top: 30px;
  margin-bottom: 80px;
  padding: 10px 20px;
  background-color: black;  
  background-image: url('./img/black-leather.png');
  color: white;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  transition: ease-in-out 0.2s;
}

.enter-button:hover {
  background-color: #1e150f;
  background-image: url('./img/brown-leather.png');
  transform: scale(1.03);
}

/* Hide the scrollbar for Firefox */
.landing-page {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Hide the scrollbar for Chrome, Edge, and Safari */
.landing-page::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
}

.landing-page::-webkit-scrollbar-thumb {
  background-color: transparent;
}

@media (max-width: 768px) {
  .mantra {
    font-size: 1.5rem;
  }

  .logo {
    max-width: 90%;
  }
  .mission0,
  .mission1,
  .mission2,
  .mission3,
  .mission4 {
    font-size: 1rem;
    text-align: justify-content;
  }
  
}